<template>
  <div class="body">
    <div ref="myDiv" style="left: 0;right: 0;top: 0;position: fixed;z-index:999;background: #ffffff;width: 100vw;">
      <!-- 顶部细节   -->
      <div class="margin-left30 margin-top30 topDivClass" style="padding-top: var(--30);">
        <div class="flex_able margin-left30 font-size28 font-colorFFFFFF line-height40"
             style="align-items: start">
          账户ID：{{ userNum }}
        </div>
        <img class="margin-left30 margin-top12" style="width: var(--260);height: var(--20);"
             src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/c79b9c86-56fe-4bca-a2cf-57b40645b46c.png"
             alt="">
        <div class="flex_able margin-top16 margin-left30 font-size28 font-colorFFFFFF line-height40"
             style="align-items: center">
          <span>余额：</span>
          <span class="font-size36 font-colorFFFFFF font-weightBold line-height50">{{ balanceX }}</span>
          <span style="margin-left: var(--14);">X币</span>
        </div>
        <div v-if="false" class="font-size26 font-colorFFFFFF line-height36 margin-top2" style="margin-left: var(--114);">
          ≈{{ usdt }} USDT
        </div>
      </div>
      <!--  日期选择  -->
      <div @click="choiceDate" class="flex_able margin-top40"
           style="background: #ffffff;padding-left: var(--30);padding-right: var(--30);width: 100%">
        <div class="font-size32 font-color1B1B1B" style="flex: 1;font-weight: bold;">
          账单详情
        </div>
        <title-arrow
            textColor="#999999"
            lineHeight="var(--34)"
            textSize="var(--25)"
            imageWidth="var(--24)"
            imageHeight="var(--14)"
            marginLeft="var(--8)"
            imageUrl="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/47c803e7-4ab3-46bd-9397-b317fa2fe4ae.png"
            :text="selectDate"/>
      </div>
      <div style="height: var(--1);background: #DDDDDD;border-radius: var(--4);margin-top: var(--24);"></div>
      <!--  流式九宫格  -->
      <div class="grid margin-right30 margin-top10 margin-bottom10"
           style="z-index: 100;background: #FFFFFF;width: 100vw;position: sticky">
        <div @click="clickFunctionType(index)" v-for="(item,index) in tabList" :key="item.id"
             class="item flex_center" :class="{ 'item-selected': activeIndex == index }">
          <div class="item-title" :class="{ 'item-title-selected': activeIndex == index }">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>

    <div :style="{'padding-top': divHeight + 'px'}">
      <van-list v-model=item.loading :immediate-check="false" :finished=item.finished
                :finished-text=item.finishedText
                @load="getTabListContent(item)"
                v-if="item.contentList.length > 0">
        <div v-for="(item, index) in item.contentList" :key="index"
             style="height: var(--138);">
          <div class="flex_center"
               style="justify-content: center;height: var(--138);">
            <div
                style="margin-left: var(--30);margin-right: var(--30);flex:1;font-size: var(--30);
                  font-weight: 400;color: #1B1B1B;">
              <div class="oneLine" style="line-height: var(--42)">
                {{ item.flowTypeDetail }}
              </div>
              <div
                  style="font-weight: 400;margin-top: var(--6);font-size: var(--24);color: #999999;line-height: var(--34)">
                {{ item.flowTypeExplain }}
              </div>
            </div>
            <div
                style="margin-right: var(--30);display:flex;flex-direction:column;align-items:end;font-size: var(--28);font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1B1B1B;">
              <div v-if="item.rollType == 1" class="oneLine"
                   style="max-width:var(--350);width: 100%;line-height: var(--40);text-align: right;color:#026AFC;">
                +{{ item.amount }}X币
              </div>
              <div v-if="item.rollType != 1" class="oneLine"
                   style="max-width:var(--350);width: 100%;line-height: var(--40);text-align: right;color: #FF1F1F">
                -{{ item.amount }}X币
              </div>
              <div class="oneLine"
                   style="max-width:var(--350);width: 100%;text-align: right;font-weight: 400;font-size: var(--24);color: #999999;height: var(--34);margin-top: var(--10);">
                {{ item.ctime.replace("T", " ") }}
              </div>
            </div>
          </div>

        </div>
      </van-list>

      <div
          style="text-align: center;width: 94.8vw;border-radius: 1.3vw;background: #fff;position: relative;line-height: 19.7vw;color: #000;font-size: 4vw;font-weight: bold;margin: 2.8vw auto auto auto;"
          v-if="item.showNoData">暂无明细
      </div>
    </div>
    <!-- 时间选择器 -->
    <date-choice-dialog type="year-month" ref="controlDateDialog" @handleChange="dateCallback"
                        @handleChangeSelectDate="selectDateCallback"/>
  </div>
</template>

<script>

import TitleArrow from "@/components/TitleArrow.vue";
import {Api} from "@/utils/net/Api";
import {Toast} from "vant";
import DateChoiceDialog from "@/components/DateChoiceDialog.vue";
import {Constants} from "../../../utils/constants";

export default {
  components: {DateChoiceDialog, TitleArrow},
  data() {
    return {
      item: {contentList: []},
      divHeight: 0,
      activeIndex: 0,
      usdt: 0,
      userNum: "--",
      totalBalance: "",//总影响力
      balanceX: "",//X币数量
      selectDate: "",//显示选中的时间
      monthNum: "",//给接口的时间
      tabList: [],
      gridData: []
    }
  },
  created() {
    //开始5
    try {
      //设置标题
      document.title = "查看明细"
    } catch (error) {
      console.log("常规错误:" + error)
    }
    let userNum = localStorage.getItem(Constants.userNum);
    if (userNum) {
      this.userNum = userNum
    }
    this.queryPowerAccount()
  },
  mounted() {
    this.$nextTick(() => {
      //获取顶部的高度
      const height = this.$refs.myDiv.clientHeight;
      console.log('The height of myDiv is:', height);
      this.divHeight = height
    });
  },
  methods: {
    clickFunctionType(index) {
      console.log(index)
      this.activeIndex = index
      this.tabList = this.getTabList()
      this.item = this.tabList[this.activeIndex]
      this.getTabListContent(this.item)
    },
    queryPowerAccount() {
      let params = {
        assetType: Constants.X_COIN,
      }
      Api.queryPowerAccount(params).then((result) => {
        if (result.code === 100) {
          this.balanceX = result.data?.accountVo?.balance
          this.usdt = result.data?.usdt
        } else {
          Toast(result.msg);
        }
      })
    },
    getTabList() {
      return [
        {
          title: "全部",
          assetType: Constants.X_COIN,
          finished: false,
          showNoData: false,
          pageNo: 1,
          loading: false,
          finishedText: "没有更多了",
          pageSize: 10,
          contentList: [],
        }
        // ,
        // {
        //   title: "算力收益",
        //   assetType: 2021,
        //   finished: false,
        //   showNoData: false,
        //   pageNo: 1,
        //   loading: false,
        //   finishedText: "没有更多了",
        //   pageSize: 10,
        //   contentList: [],
        // },
        // {
        //   title: "充值",
        //   assetType: 2021,
        //   finished: false,
        //   showNoData: false,
        //   pageNo: 1,
        //   loading: false,
        //   finishedText: "没有更多了",
        //   pageSize: 10,
        //   contentList: [],
        // },
        // {
        //   title: "提取",
        //   assetType: 2021,
        //   finished: false,
        //   showNoData: false,
        //   pageNo: 1,
        //   loading: false,
        //   finishedText: "没有更多了",
        //   pageSize: 10,
        //   contentList: [],
        // },
        // {
        //   title: "兑换算力",
        //   assetType: 2021,
        //   finished: false,
        //   showNoData: false,
        //   pageNo: 1,
        //   loading: false,
        //   finishedText: "没有更多了",
        //   pageSize: 10,
        //   contentList: [],
        // },
        // {
        //   title: "旷工费",
        //   assetType: 2021,
        //   finished: false,
        //   showNoData: false,
        //   pageNo: 1,
        //   loading: false,
        //   finishedText: "没有更多了",
        //   pageSize: 10,
        //   contentList: [],
        // }
      ]
    },
    //组件回调 经过各种判断后返回加密后文字
    selectDateCallback(value) {
      console.log(value);
      this.selectDate = value
    },
    //时间选择器 处理后给接口的日期格式
    dateCallback(value) {
      console.log(value);
      this.monthNum = value
      this.tabList = this.getTabList()
      this.item = this.tabList[this.activeIndex]
      this.getTabListContent(this.item)
      // this.tabList.forEach((item, index) => {
      //   this.getTabListContent(item, index)
      // })
    },
    choiceDate() {
      //显示日期
      this.$refs.controlDateDialog.controlDialog(true);
    },
    getTabListContent(item) {
      let tab = item
      if (tab.finished) {
        return;
      }
      let data = {
        pageNo: tab.pageNo,
        pageSize: tab.pageSize,
        assetType: tab.assetType,
        monthNum: this.monthNum
      }
      let apiObj = Api.queryPowerRecord(data);
      if (apiObj) {
        apiObj.then(res => {
          if (res.code === 100) {
            let pageInfo = res.data.pageInfo
            let resData = pageInfo,
                list = resData.list;
            if (resData.isLastPage) { // 没有更多了
              tab.finished = true;
            }
            tab.contentList = tab.contentList.concat(this.handleList(list));
            item.showNoData = item.contentList.length === 0
            tab.pageNo = resData.nextPage;
            tab.loading = false;
          } else {
            Toast(res.msg);
          }
        })
      }
    },
    handleList(list) {
      // let myList = []
      // list.forEach(item => {
      //   myList.push(Object.assign({}, item))
      // })
      // list.forEach(item => {
      //   myList.push(Object.assign({}, item))
      // })
      // list.forEach(item => {
      //   myList.push(Object.assign({}, item))
      // })
      return list;
    },
  },
}
</script>

<style scoped>
.body {
  background-color: #ffffff;
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(4, 1fr);
}

.item {
  margin-top: var(--20);
  padding-left: var(--30);
  padding-right: var(--30);
  margin-left: var(--30);
  height: var(--66);
  background: #F7F7F9;;
  border-radius: var(--12);
}

.item-selected {
  background: #EEF6FF;
}

.item-title {
  font-size: var(--30);
  line-height: var(--42);
  color: #666666;
}

.item-title-selected {
  color: #268DFF;
  font-weight: bold;
}

.topDivClass {
  width: var(--690);
  height: var(--242);
  background-size: 100% 100%;
  background-image: url("https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/9fd65f7f-bca6-43df-b93a-d16e278cdcd3.png");
}
</style>
